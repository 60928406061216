import React from 'react';
import Logo from '../images/TDVBgroupImages/TDVB-logo-2021.png';
import '../styles/logo-page.scss';

const logoPage = () => {
  //return null;
  return (
    <section className="logo-container">
      <img src={Logo} width="800vh"></img>
    </section>
  );
};

export default logoPage;
